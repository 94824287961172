body {
  background-color: #eaf1f9;
  color: #333; }
  body.modal-open {
    overflow-y: hidden; }

.p-float-label .p-inputtext {
  padding: 0.5rem 0.5rem; }

.p-float-label label {
  white-space: nowrap; }

.p-rightt {
  position: relative !important;
  left: -89px !important; }

.p-same-line {
  display: inline-block; }

.field-checkbox {
  top: 160px;
  left: 1500px;
  color: #ff0000;
  position: fixed;
  zoom: 90%; }

.field-checkbox-ll {
  top: 283px;
  left: 1528px;
  color: #ff0000;
  position: fixed;
  zoom: 90%; }

.p-same-line-2 {
  display: inline; }

.p-half-width .p-inputtext {
  width: 172px !important; }

.p-half-width .p-dropdown {
  width: 172px !important; }

.p-align {
  left: 1123px;
  top: -749px;
  zoom: 95%; }

.p-fileup-pos {
  zoom: 90%;
  top: -671px;
  right: -1190px; }

.p-shiftt-comments {
  zoom: 110%;
  right: 480px;
  bottom: 210px !important; }

.p-grid{
	column-gap: 15px;
}

.p-cstatus {
  zoom: 85%;
  left: -660px !important;
  bottom: 485px !important;
  position: relative; }

/*.p-dropdown-items-wrapper{
	zoom:85%;
	left: -660px !important;
	bottom: 485px !important;
	position: relative;
}*/
.p-lstatus {
  zoom: 85%;
  left: -1140px !important;
  bottom: 410px !important;
  position: relative; }

.p-istatus {
  zoom: 85%;
  left: 313px !important;
  bottom: 415px !important;
  position: relative; }

.p-buttons-align {
  align-self: center !important;
  bottom: 35px !important;
  position: relative; }

.cp-btn {
  left: 5px;
  width: 100px !important; }

.close-btn {
  right: -100%;
  background-color: #e03f3f !important;
  border-color: #e03f3f; }

.p-hid-bol {
  bottom: 340px;
  right: 90px;
  width: 405px;
  z-index: 9;
  position: fixed; }

.p-hid-waybill {
  bottom: 270px;
  right: 90px;
  width: 405px;
  z-index: 9;
  position: fixed; }

.p-hid-invoice {
  bottom: 198px;
  right: 90px;
  width: 405px;
  z-index: 9;
  position: fixed; }

.p-hid-docs {
  bottom: 125px;
  right: 90px;
  width: 405px;
  z-index: 9;
  position: fixed; }

.p-hid-invoice2 {
  bottom: 165px;
  right: 250px;
  position: fixed; }

.p-up {
  position: relative;
  bottom: 70px; }

.p-btn-left {
  position: relative;
  left: -120px; }

.cp-btn-shift {
  left: 1px !important;
  width: 250px !important; }

.p-fileupload .p-fileupload-buttonbar .p-button {
  width: 140px; }

.p-fileupload .p-fileupload-buttonbar {
  display: flex;
  justify-content: center; }

.p-fileupload .p-fileupload-content {
  padding: 1em !important; }

.p-fileupload .p-fileupload-row > div {
  padding: 1px !important;
  width: auto; }

.p-fileupload .p-fileupload-row .p-fileupload-filename {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  inline-size: 100px; }

.p-fileupload .p-fileupload-row > div > img {
  display: none; }

.p-datatable-tbody > tr > td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  min-width: 100px; }

.p-datatable-thead > tr > th {
  white-space: wrap;
  max-width: 100px;
  min-width: 100px; }

.p-csz-right {
  right: 180px !important;
  position: relative; }

.p-button.p-button-icon-only {
  width: 3.5rem;
  padding: 0.5rem 0;
  position: relative; }

.p-button.button-nowrap {
  white-space: nowrap; }

.required {
  color: red; }

div.p-datepicker.p-component.p-connected-overlay-enter-done {
  min-width: 418px !important;
  display: inline-grid; }

.p-calendar .p-component .p-inputwrapper {
  width: 172px; }

/*.p-loadlocation-pos{
	right: -200px !important;
	top:-255px !important;
	position: relative;

}*/
.p-divider-content {
  font-size: 0.8rem;
  font-weight: 700;
  background-color: #e1e2e3 !important;
  border-radius: 3px; }

body {
  zoom: 80%; }

body::-webkit-scrollbar {
  display: block;
  width: 5px; }

body::-webkit-scrollbar-track {
  background: transparent; }

body::-webkit-scrollbar-thumb {
  background-color: grey;
  position: sticky;
  border-right: none;
  border-left: none; }

body::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px; }

body::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px; }

.p-field > label {
  display: inline-block;
  margin-bottom: 0.5rem;
  padding-left: 8px; }

.p-datatable table {
  table-layout: auto; }

.controlled-version .version-container {
  position: relative;
  margin-bottom: 0px; }

.version-container {
  text-align: center;
  font-weight: 500;
  color: #8a97a7;
  font-size: 13px;
  width: 100%;
  margin-bottom: 0px;
  vertical-align: bottom; }

.current-env-mode {
  text-align: center;
  font-size: 12px;
  background-color: #44af69;
  padding: 3px;
  vertical-align: middle;
  font-weight: 600;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 3px; }

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.2rem;
  font-size: 13px; }

.navbar.fixed-top.devmode {
  top: 24px; }

.center-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .center-title h1 {
    font-weight: 800; }
    .center-title h1 .asw-title1 {
      color: #0054ab;
      font-size: 30px; }
    .center-title h1 .asw-title2 {
      color: #EE283A; }

nav.bg-light {
  background-color: #fff !important; }

h4.main-title {
  font-weight: 800; }

.table-container {
  display: table;
  width: 100%;
  scrollbar-color: #0a4c95 #dcdcdc; }
  .table-container.custom-no-display-table {
    display: inherit; }

.card {
  padding: 1.25rem; }

.nav-link {
  padding: 0; }

.amerigas-logo {
  max-width: 150px; }

.blue-amerigas-bg {
  background-color: #0054ab; }

.blue-amerigas-txt {
  color: #0054ab; }

.blue-100-amerigas-bg {
  background-color: #172fe6; }

.padder-top {
  margin-top: 5rem !important; }

.p-button.p-button-link {
  padding: 0; }

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: transparent; }

.form-warning .p-inline-message-error {
  width: auto;
  margin-bottom: 10px;
  display: inline-block; }

.search-error {
  margin-top: -50px;
  padding: 20px 0;
  color: #ee283a; }

.no-matching-filters {
  display: none;
  text-align: center;
  padding: 2rem;
  font-weight: 700; }
  .no-matching-filters.active {
    display: block; }

.spinner-dark-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.2); }
  .spinner-dark-bg.historical-readings {
    background: transparent;
    position: relative;
    height: 50px; }
    .spinner-dark-bg.historical-readings .p-progress-spinner {
      top: 70% !important;
      -ms-transform: translate(-50%, -70%) !important;
      transform: translate(-50%, -70%) !important; }
  .spinner-dark-bg .inner-spinner-bg {
    position: relative;
    width: 100%;
    height: 100%; }
    .spinner-dark-bg .inner-spinner-bg .p-progress-spinner {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }

.no-access-container .no-access-inner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  max-width: 480px; }

.no-access-container .no-access-icon {
  color: #35454c;
  float: left;
  margin-right: 15px;
  font-size: 5em; }

.no-access-container h3 {
  color: #0054ab; }

/* FAQ */
#faq {
  width: 70vw;
  margin: 0 auto;
  padding-top: 3rem; }

#faq h2 {
  text-align: center;
  margin-bottom: 2rem; }

#faq ol {
  list-style: none;
  padding: 0; }

#faq ol > li {
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: 30px;
  margin-bottom: 8px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d1d1d1; }
  #faq ol > li:before {
    content: "";
    width: 8px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #2196f3; }

#faq h3.question {
  width: 100%;
  z-index: 1;
  font-size: 1.4rem;
  transition: all 0.3s; }

#faq .toggle:checked ~ h3.question,
#faq li:hover h3.question {
  color: #0054ab; }

#faq .icon {
  height: 18px;
  position: absolute;
  width: 18px;
  right: 0;
  top: 11px;
  z-index: 2; }

#faq .icon:before,
#faq .icon:after {
  background: #607d8b;
  content: '';
  height: 4px;
  right: 14px;
  position: absolute;
  top: 10px;
  width: 18px;
  transition: transform 200ms ease;
  border-radius: 2px; }

#faq .toggle:checked ~ .icon:before,
#faq .toggle:checked ~ .icon:after,
#faq li:hover .icon:before,
#faq li:hover .icon:after {
  background: #0054ab; }

#faq .toggle:not(:checked) ~ .icon:before {
  transform: rotate(180deg); }

#faq .toggle:not(:checked) ~ .icon:after {
  transform: rotate(90deg); }

#faq .toggle {
  width: 100%;
  height: 40px;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3; }

#faq .border {
  height: 40px;
  border-bottom: 1px solid #fdfdfd; }

#faq li:hover .border,
#faq .toggle:checked ~ .border {
  border-bottom: 1px solid #0054ab; }

#faq .answer {
  height: 0;
  font-size: 16px;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  transition: .4s ease; }

#faq .toggle:checked ~ .answer {
  height: auto;
  opacity: 1;
  z-index: 2; }

#faq .answer > p {
  margin: 0;
  text-align: justify; }

@media only screen and (max-width: 600px) {
  .no-access-container .no-access-inner-container {
    text-align: center; }
  .no-access-container .no-access-icon {
    color: #35454c;
    float: none;
    margin-right: 0;
    margin-top: 15px;
    font-size: 4em; }
  .no-access-container h3 {
    color: #0054ab;
    font-size: 18px; }
  #faq {
    width: 100%; } }

.p-dropdown {
  min-height: 40px; }

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 1s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

.help-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  text-align: center;
  max-width: 654px;
  background-color: #fff;
  padding: 25px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.06);
  border-bottom: 3px solid #d25a65; }
  .help-container span {
    font-weight: 600; }
  .help-container h2 {
    color: #1E4CA1;
    margin-bottom: 20px;
    font-size: 2.4rem; }

.invoice-cards {
  border-radius: 4px;
  box-shadow: none;
  border: 2px solid #d9e9f9;
  color: #424649;
  transition: all 0.5s; }
  .invoice-cards.active {
    border: 3px solid #fff;
    background-color: #ecf8ff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; }
    .invoice-cards.active .p-card-title {
      color: #2196f3; }
    .invoice-cards.active .p-card-subtitle,
    .invoice-cards.active .invoice-amount-text {
      color: #72899e; }
  .invoice-cards .invoice-amount {
    font-size: 24px;
    font-weight: 700;
    color: #36ab6f;
    background-color: #ecf7f1;
    border-radius: 50%;
    text-align: center;
    height: 70px;
    width: 70px;
    line-height: 67px;
    border: 1px solid #afe6db; }
    .invoice-cards .invoice-amount.zero {
      color: #adb5b9;
      background-color: #e8f0f5;
      border: 1px solid #e8ecef; }
  .invoice-cards .invoice-amount-text {
    font-size: 13px;
    font-weight: 700;
    color: #a3afba;
    margin-top: -4px; }
  .invoice-cards .p-card-content {
    padding: 0; }
  .invoice-cards .p-card-body {
    font-size: 14px; }
  .invoice-cards .invoice-date {
    color: #587cbe; }
  .invoice-cards .invoice-due {
    color: #ec818a; }
  .invoice-cards .p-card-title {
    margin-bottom: -2px;
    font-size: 20px; }
  .invoice-cards .invoice-amount-container {
    margin-bottom: 15px; }
  .invoice-cards .p-card-subtitle {
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #a3afba; }
  .invoice-cards .invoice-total {
    font-size: 1.5rem;
    font-weight: 700; }
  .invoice-cards .button-container .p-button {
    width: 100%; }

.coming-soon {
  text-align: center; }
  .coming-soon .coming-soon-text {
    letter-spacing: 3px;
    color: #1e4ca1;
    font-size: 26px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: -4px; }
  .coming-soon .under {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #ee283a; }

.web-app {
  font-size: 14px;
  font-weight: 600;
  transition: all 0.5s;
  color: #7d97b5; }
  .web-app.active {
    color: #0054ab; }

.editor-cell {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  padding: 6px;
  border-radius: 2px;
  -webkit-box-shadow: inset 0px 0px 1px 0px rgba(0, 0, 0, 0.42);
  box-shadow: inset 0px 0px 1px 0px rgba(0, 0, 0, 0.42);
  width: 100px;
  display: inline-block;
  min-height: 35px; }
  .editor-cell.date-custom-width {
    width: 120px; }
  .editor-cell.p-invalid {
    border-color: #e96262;
    border-width: 3px; }

.p-cell-editing .p-inputtext {
  width: 100%; }

.save-container {
  position: fixed;
  top: 0;
  right: 128px;
  z-index: 999;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  -webkit-box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid #d7e1e7;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }
  .save-container .p-button.p-button-sm {
    font-size: 0.775rem;
    padding: 0.3375rem 0.675rem; }

.sched-ship-date-icon .p-column-title:after {
  content: 'i';
  margin: 4px;
  background-color: #dee7e9;
  padding: 0px 7px !important;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #b1b2b3; }

.more-info-icon {
  background-color: #dee7e9;
  padding: 0px 4px;
  font-weight: 600;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #b1b2b3;
  cursor: pointer; }
  .more-info-icon .pi {
    font-size: 14px;
    color: #0a4c95; }

.tips-text {
  font-size: 16px;
  font-weight: 500;
  color: #7f7f7f; }

.p-sidebar-bottom {
  bottom: 0;
  right: 40px;
  width: 620px;
  height: 36.3rem;
  left: auto;
  border-top: 5px solid #0054ab; }
  .p-sidebar-bottom p {
    font-size: 13px; }
  .p-sidebar-bottom.p-sidebar .p-sidebar-header {
    padding: 0 !important; }
  .p-sidebar-bottom .highlight-bold-text {
    color: #0054ab; }

.highlight-header {
  background: #ebed6d !important; }

.simple-highlight {
  background-color: lightgoldenrodyellow !important;
  padding: 0.1em 0.2em; }

.navbar {
  -webkit-box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.06);
  font-weight: 600;
  color: #6c757d; }

.dropdown.inactive .dropdown-menu {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0; }
  .dropdown.inactive .dropdown-menu.menu {
    display: inherit;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s; }

.dropdown.active .dropdown-menu {
  display: inherit;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0; }
  .dropdown.active .dropdown-menu.menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s; }

.navbar-light .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  margin-left: 27px;
  font-size: 14px;
  margin-top: 4px;
  font-weight: 700; }
  .navbar-light .navbar-nav .nav-link.active {
    color: #2196f3; }
    .navbar-light .navbar-nav .nav-link.active .p-badge {
      background: #1e4ca1;
      color: #ffffff; }
  .navbar-light .navbar-nav .nav-link.sub-link {
    margin-left: 0;
    margin-top: 0;
    padding: 10px 14px 10px 16px;
    border-top: 1px solid #edf0fb;
    white-space: nowrap; }
    .navbar-light .navbar-nav .nav-link.sub-link.active {
      background-color: #edf0fb; }

.navbar-collapse.collapse.show ul {
  margin-top: 15px; }

.navbar-collapse.collapse.show li {
  padding: 10px 0; }

.navbar-collapse.collapse.show .nav-item.dropdown.inactive {
  height: 50px; }

.p-badge {
  background: #ebf4ff;
  color: #4d657d;
  font-size: 0.70rem;
  font-weight: 700;
  min-width: 22px;
  height: 22px;
  line-height: 22px;
  float: right; }

.app-spinner {
  z-index: 9999 !important; }

.p-panel .p-panel-header {
  background-color: #1F4166;
  color: #fff; }

.p-panel-icons .pi {
  color: #fff; }

.percent-container,
.search-btn {
  min-width: 114px; }
  .percent-container.delivery-term,
  .search-btn.delivery-term {
    min-width: auto; }
  .percent-container.p-lg-3, .percent-container.p-lg-1,
  .search-btn.p-lg-3,
  .search-btn.p-lg-1 {
    padding: 0.5rem !important; }

.rail-pipe-truck-filters {
  margin-top: -12px;
  margin-bottom: 16px; }
  .rail-pipe-truck-filters.location-inv {
    margin-top: 12px; }
  .rail-pipe-truck-filters label {
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 20px;
    vertical-align: middle; }

.orders-showCancelled {
  margin-bottom: 0;
  margin-left: 10px;
  vertical-align: middle; }

.showCancelled-container {
  background-color: #f5f7fd;
  border: 1px solid #e7e7ec;
  margin-left: 50px;
  border-radius: 3px; }

.orders-toggle {
  padding-left: 10px;
  margin-top: -32px; }
  .orders-toggle label {
    white-space: nowrap; }

.multiselect-custom .item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: .5rem;
  background-color: #2196f3;
  color: #fff;
  font-size: 12px; }

.tank-readings-filters .p-lg-1 {
  padding: 0.5rem !important; }

.run-down-btn.p-lg-1 {
  padding: 1rem !important; }

.search-panel.p-lg-4 {
  padding: 0.5rem !important; }

.search-panel .p-panel-header {
  background-color: #1f4166;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: none;
  padding: 20px;
  font-size: 20px;
  padding-bottom: 4px; }
  .search-panel .p-panel-header .p-panel-title {
    color: #fff;
    font-weight: 700; }

.search-panel .p-panel .p-panel-content {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #1f4166;
  background: linear-gradient(180deg, #1f4166 0%, #416fa1 100%);
  padding-bottom: 34px; }
  .search-panel .p-panel .p-panel-content .p-field {
    margin-bottom: 0; }
  .search-panel .p-panel .p-panel-content .search-btn.p-lg-4 {
    padding: 0.5rem !important;
    padding-top: 0 !important; }

.p-panel-toggleable .p-panel-content {
  padding-top: 32px;
  padding-bottom: 6px; }

.movement-date {
  color: #9a9bab;
  font-size: 14px;
  margin-left: 10px; }

.custom-calendar-container .p-calendar {
  position: absolute;
  z-index: 9; }

.clear-btn.p-lg-1 {
  padding-top: 1rem !important; }

.no-results-msg {
  text-align: center;
  padding: 2rem;
  font-weight: 700; }

.expand-collapse-buttons {
  margin-top: 25px; }

.orders-screen-location-dropdown.p-lg-3 {
  padding: 0.5rem !important; }

.invoice-management-checkboxes .p-checkbox {
  vertical-align: middle; }

.invoice-management-checkboxes label {
  text-align: center; }

.status-filter {
  padding-right: 10px; }

.custom-panel .p-panel-header {
  background-color: #f8f9fa; }

.custom-panel .p-panel-title {
  color: #0054ab !important;
  font-weight: 700 !important; }

.rail-hub-sidebar .p-sidebar-header {
  padding: 0;
  background-color: #f8f9fa;
  border-bottom: 1px solid #d1d4d7 !important; }

.rail-hub-sidebar .p-sidebar .p-sidebar-header .p-sidebar-close,
.rail-hub-sidebar .p-sidebar .p-sidebar-header .p-sidebar-icon {
  margin-top: 2 px; }

.rail-hub-sidebar .sidebar-icon {
  position: absolute;
  top: -33px;
  left: 0px;
  background-color: #1E4CA1;
  color: #fff;
  padding: 4px 12px; }
  .rail-hub-sidebar .sidebar-icon .pi {
    font-size: 14px; }

.rail-hub-sidebar h4 {
  margin-bottom: 22px;
  margin-top: 32px; }

.rail-hub-sidebar .p-field {
  margin-bottom: 1.8rem; }

.rail-hub-sidebar .p-sidebar-content {
  overflow: inherit; }

.p-sidebar-right {
  width: 32rem;
  height: 100%; }

.filters-selected {
  display: inline-flex; }
  .filters-selected .filter-container.active {
    margin: 0 10px; }
  .filters-selected .selected-filters-txt {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #7b8089; }
    .filters-selected .selected-filters-txt .pi {
      font-size: 12px;
      font-weight: 700; }
  .filters-selected .selected-filter {
    font-size: 12px;
    display: inline;
    background-color: #dae3f3;
    border: 1px solid #c9d1df;
    padding: 3px;
    border-radius: 4px;
    font-weight: 500;
    color: #7b8089;
    margin: 0 3px; }

.small-txt {
  display: block;
  margin-top: 5px;
  color: #9ca7ad;
  font-weight: 400; }

.reset-filters-btm-rail {
  margin-top: 20px; }

.table-container {
  background-color: #fff;
  margin-top: 1rem; }
  .table-container h5 {
    padding-top: 14px;
    font-weight: 700;
    color: #333; }

.p-datatable-scrollable-body {
  overflow-y: scroll; }

.status-warnings {
  background: #feedaf;
  color: #333; }

.status-nowarnings {
  background-color: #f7f9f9;
  color: #23547b; }

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px; }

.edit-item {
  color: #bbc7cd;
  transition: all 0.2s;
  cursor: pointer; }
  .edit-item:hover {
    color: #fccc55; }

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.244rem 0.444rem !important; }

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.244rem 0.444rem;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: warp !important; }

.align-date-center {
  text-align: center !important; }

.p-tooltip {
  width: 100% !important; }
  .p-tooltip .p-tooltip-text {
    background: #333 !important;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.007) !important;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.007) !important;
    font-size: 14px !important; }

.expand-collapse-buttons {
  margin-right: 15px; }
  .expand-collapse-buttons .p-togglebutton.p-button.p-highlight,
  .expand-collapse-buttons .p-togglebutton.p-button.p-highlight:hover {
    background: #607d8b;
    border-color: #607d8b;
    color: #ffffff; }

.warnings-column {
  width: 142px; }

.sub-column-header {
  border-top: 1px solid #d1d4d7 !important; }
  .sub-column-header.row-expander {
    width: 45px;
    padding: 5px !important; }
  .sub-column-header.district-column {
    width: auto; }
  .sub-column-header.location-column {
    width: auto; }
  .sub-column-header.state-column, .sub-column-header.percent-column {
    width: 80px;
    padding: 5px !important; }

.overridden-table {
  margin-top: 1rem;
  margin-left: 1rem; }

.overridden-columns {
  text-align: center !important;
  position: relative; }

.overridden-table {
  max-width: 600px; }

.roundedChecks {
  background-color: #8f9bb9;
  color: #fff;
  border-radius: 50%;
  display: inline-block; }
  .roundedChecks .pi {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 20px;
    height: 20px;
    font-size: 10px;
    font-weight: 600; }

.custom-row-class-warning {
  background-color: rgba(254, 237, 175, 0.36) !important;
  border-left: 4px solid #ffd128; }

.custom-row-class-orange {
  background-color: rgba(225, 91, 19, 0.16) !important;
  border-left: 4px solid #ff6500; }

.user-assign {
  color: #abb2b7; }
  .user-assign:hover {
    cursor: pointer; }

.daily-demand-text {
  margin-left: 50px; }

.no-results-rundown .no-results-msg {
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0; }

.export-green-button {
  color: #43aa8b !important;
  border-color: #43aa8b !important; }
  .export-green-button .pi {
    color: #43aa8b !important; }

a:hover {
  text-decoration: none !important; }

.method-types {
  width: 60px;
  text-align: center !important; }

.Flipped,
.Flipped .Content {
  transform: rotateX(180deg);
  scrollbar-color: #0A4C95 #dcdcdc;
  -webkit-overflow-scrolling: touch; }
  .Flipped::-webkit-scrollbar-track,
  .Flipped .Content::-webkit-scrollbar-track {
    background: #dcdcdc; }
  .Flipped::-webkit-scrollbar,
  .Flipped .Content::-webkit-scrollbar {
    height: 12px;
    background-color: #dcdcdc; }
  .Flipped::-webkit-scrollbar-thumb,
  .Flipped .Content::-webkit-scrollbar-thumb {
    background: #0A4C95;
    transition: all 0.5s; }
  .Flipped::-webkit-scrollbar-thumb:hover,
  .Flipped .Content::-webkit-scrollbar-thumb:hover {
    background: #1F4166; }

.records-title {
  padding-top: 14px;
  font-weight: 700;
  color: #333; }

.p-filter-column input {
  width: 100%;
  font-size: 12px; }

th.p-filter-column {
  background-color: #d9e9f5 !important;
  padding: 0.555rem !important; }

.cvs-botton-padder-top {
  margin-top: 20px; }

.my-worlist-padder {
  margin-top: 20px; }

.right-aligned-columns {
  text-align: right !important; }

.center-aligned-columns {
  text-align: center !important; }

.subtable-summary-ap {
  margin: 0.5rem; }

.edit-blue {
  color: #0288d1;
  cursor: pointer;
  font-size: 14px; }

.edit-red {
  color: #d32f2f;
  cursor: pointer;
  font-size: 14px; }

.isDeleted {
  background-color: #ffdada !important;
  border-left: 0.07rem solid red; }

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #f4f9ff;
  color: #495057; }

.add-comments {
  position: relative;
  cursor: pointer; }
  .add-comments .pi-comment {
    font-size: 22px;
    color: #b9c0cb; }
  .add-comments.active-comments .pi-comments {
    color: #70b1e4;
    font-size: 20px; }
  .add-comments .pi-plus {
    color: #b9c0cb;
    position: absolute;
    left: 7px;
    top: 6px;
    font-size: 9px;
    font-weight: 600; }

.status-c {
  text-align: center;
  font-weight: 600;
  color: #44af69;
  background-color: #e6f5eb;
  border: 1px solid #c5e3cf;
  width: 24px;
  margin: 0 auto;
  border-radius: 4px; }

.status-a {
  text-align: center;
  font-weight: 600;
  color: #7c99d9;
  background-color: #e9eafa;
  border: 1px solid #c0cbda;
  border-radius: 4px;
  width: 24px;
  margin: 0 auto; }

.status-i {
  text-align: center;
  font-weight: 600;
  color: #8b8d94;
  background-color: #e9e9ee;
  border: 1px solid #c0cbda;
  border-radius: 4px;
  width: 24px;
  margin: 0 auto; }

.inactive-row-status {
  pointer-events: none !important; }

.right-align-column {
  text-align: right !important; }

.date-container {
  position: relative;
  float: right;
  width: 120px; }
  .date-container .clear-date-icon {
    position: absolute;
    left: 6px;
    top: 9px;
    cursor: pointer; }
    .date-container .clear-date-icon .pi {
      color: #8c90a1;
      border: 1px solid #8c90a1;
      border-radius: 50%;
      padding: 2px;
      font-size: 12px; }

.disabled-readOnly .p-checkbox {
  pointer-events: none; }

.p-dialog .p-dialog-header {
  background: #4695ea;
  color: #fff;
  padding: 1rem; }

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #fff; }

.serial-num-detail {
  margin-top: 15px;
  margin-bottom: 40px; }
  .serial-num-detail.tank-readings {
    margin-bottom: 10px; }
  .serial-num-detail .pi {
    vertical-align: middle; }
  .serial-num-detail.location-detail {
    margin-bottom: 15px; }
    .serial-num-detail.location-detail p-fluid {
      margin-bottom: 20px; }

span.req {
  color: red; }

.search-btn {
  white-space: nowrap; }

.inner-custom-dialog {
  background-color: #f4fafe;
  text-align: right;
  border-radius: 4px;
  border: 1px solid #cae6fc; }
  .inner-custom-dialog .p-field-radiobutton {
    margin-bottom: .5rem; }
  .inner-custom-dialog.locations-green-bg {
    background-color: #f6fbf6;
    border: 1px solid #d4ecd5;
    margin-bottom: 35px; }
    .inner-custom-dialog.locations-green-bg .p-field-radiobutton {
      margin-bottom: 1.5rem; }
  .inner-custom-dialog.locations-blue-bg {
    background-color: #f4fafe;
    text-align: left;
    padding: 16px;
    border: 1px solid #cae6fc; }
    .inner-custom-dialog.locations-blue-bg h5 {
      margin-bottom: 2rem; }
  .inner-custom-dialog .inner-tank-date-range {
    text-align: left; }
    .inner-custom-dialog .inner-tank-date-range label {
      display: block; }
    .inner-custom-dialog .inner-tank-date-range span,
    .inner-custom-dialog .inner-tank-date-range input {
      width: 100%; }

.locations-bluegray-bg {
  background-color: #f7f9f9;
  border: 1px solid #d9e0e3;
  padding: 16px;
  padding-top: 34px;
  margin-bottom: 36px; }

.title-text-dialog {
  font-size: 14px;
  text-align: left; }

.inner-dialog-container {
  text-align: right;
  margin-top: 8px; }

.p-field-radiobutton > label,
.p-field-checkbox > label {
  margin-left: 0.5rem;
  line-height: 1;
  vertical-align: middle;
  margin-bottom: 0;
  font-size: 16px;
  margin-right: 10px; }

.invisible-prop {
  color: #fff; }

.custom-margin-dialog-bottom {
  margin-bottom: 1rem !important; }

.sub-inner-blue-bg {
  padding: .555rem 1rem;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #cae6fc; }
  .sub-inner-blue-bg .inner-title-blue-bg {
    font-weight: 600;
    margin-bottom: 5px; }

.logistics-title {
  font-weight: 600;
  margin-bottom: 18px;
  text-align: left; }
  .logistics-title.hours {
    margin-bottom: 10px; }

.status-text-dialog {
  vertical-align: top;
  font-weight: 600;
  margin-right: 50px; }

.p-dialog-mask.p-component-overlay {
  z-index: 1000 !important; }

.fixed-top {
  z-index: 999 !important; }

.edit-tank-form,
.add-new-tank-form {
  margin-top: 20px; }

.locations-dialog .p-field {
  margin-bottom: 2rem; }
  .locations-dialog .p-field.lat-lon {
    margin-bottom: 1rem; }

.add-new-location-form {
  margin-top: 20px !important; }

.add-new-location-status-title {
  margin-bottom: 15px; }

.work-hours {
  vertical-align: top;
  width: 50px !important;
  padding: 0 !important;
  text-align: center;
  margin-right: 3px; }

.enter-close-days {
  font-size: 12px;
  padding-top: 20px; }

.map-spinner {
  height: 250px;
  text-align: center;
  padding-top: 70px;
  background: rgba(0, 0, 0, 0.2); }

.lat-lon-container {
  margin-top: 16px !important; }

.close-switcher .p-inputswitch {
  width: 2.5rem;
  height: 1.25rem;
  vertical-align: middle;
  margin-right: 15px;
  margin-top: 14px; }

.close-switcher .p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1rem;
  height: 1rem;
  left: 0.165rem;
  margin-top: -0.490rem;
  border-radius: 50%;
  transition-duration: 0.2s; }

.close-switcher .open-text {
  vertical-align: middle;
  margin-right: 15px;
  margin-top: 14px;
  display: inline-block;
  min-width: 60px; }

.hours-of-operation .p-inputtext {
  margin-bottom: 3px;
  margin-right: 10px;
  margin-left: 10px; }

.hours-of-operation.edit-mode .days-operation {
  font-weight: 600;
  margin-right: 20px;
  vertical-align: middle;
  margin-top: 14px; }

.hours-of-operation .days-operation {
  margin-right: 50px; }

.timeOfDay {
  font-weight: 600; }

.find-lat-lon-checkbox {
  margin-bottom: 0;
  margin-left: 10px;
  font-weight: 500;
  font-size: 13px; }

.find-btn {
  white-space: nowrap; }

.opened-modal {
  background-color: #cae6fc !important; }

.mrd-tank-dialog {
  width: 60vw; }

.tank-readings-edit-status {
  width: 36vw; }

@media only screen and (max-width: 1650px) {
  .mrd-tank-dialog {
    width: 85vw; }
  .tank-readings-edit-status {
    width: 70vw; } }

.dashboard-modal .p-datatable-header,
.dashboard-modal .p-datatable-thead > tr > th {
  border: none; }

.dashboard-modal .records-table > .p-datatable-wrapper {
  border: 1px solid #e8e8e8; }

a,
a:hover {
  text-decoration: none !important; }

.edit-payable-details-form .p-lg-4 {
  padding: 1rem; }

.table-container {
  display: table;
  width: 100%; }
  .table-container.custom-no-display-table {
    display: inherit; }

.rail-hub-filters .p-field {
  margin-bottom: 0; }

@media (max-width: 575.98px) {
  .rail-hub-filters .p-field {
    margin-bottom: 1rem; } }

@media (max-width: 767.98px) {
  .rail-hub-filters .p-field {
    margin-bottom: 1rem; } }

@media (max-width: 991.98px) {
  .rail-hub-filters .p-field {
    margin-bottom: 1rem; } }

@media (max-width: 1599.98px) {
  #position-types-table .description-column {
    width: auto; }
  .combined-table .position-column {
    width: auto; }
  .gl-table .glText {
    width: auto; }
  .invoice-cards .invoice-total {
    font-size: 1rem; }
  .invoice-cards .invoice-amount {
    font-size: 20px; } }
