body {
	background-color: #eaf1f9;
	//font-family: Varela Round,sans-serif;
	color: #333;


	&.modal-open {
		overflow-y: hidden;
	}
}
.p-column-filter {
	width: 70px;
	zoom: 90%;
}
.p-float-label .p-inputtext {
  padding: 0.5rem 0.5rem;  }
  .p-float-label label {
  white-space: nowrap;
}
.p-rightt{
	position: relative !important;
	left: -89px !important;

}
.p-same-line {
  display:inline-block;

  }
	.field-checkbox{
		top: 160px;
		left: 1500px;
		color: #ff0000;
		position: fixed;
		zoom: 90%;
	}
	.field-checkbox-ll{
		top: 283px;
		left: 1528px;
		color: #ff0000;
		position: fixed;
		zoom: 90%;
	}

	.p-same-line-2 {
	  display: inline;
	  }

.p-half-width .p-inputtext{
	width: 172px !important;
}
.p-half-width .p-dropdown{
	width: 172px !important;
}
.p-align{
  left: 1123px;
  top: -749px;
	zoom:95%;
  }
.p-fileup-pos{
zoom:90%;
top: -671px;
right: -1190px;
}
.p-shiftt-comments{
	zoom:110%;
	right: 480px;
	bottom: 210px !important;
}
.p-cstatus{
	zoom:85%;
	left: -660px !important;
	bottom: 485px !important;
	position: relative;
}
/*.p-dropdown-items-wrapper{
	zoom:85%;
	left: -660px !important;
	bottom: 485px !important;
	position: relative;
}*/
.p-lstatus{
	zoom:85%;
	left: -1140px !important;
	bottom: 410px !important;
	position: relative;
}
.p-istatus{
	zoom:85%;
	left: 313px !important;
	bottom: 415px !important;
	position: relative;
}
.p-buttons-align{
	align-self: center !important;
	bottom: 35px !important;
	position: relative;
}
// .p-inputtextarea{
// 	width: 360px !important;
// }
.cp-btn{
left: 5px;
width: 100px !important;
}
.close-btn{
	right: -100%;
	background-color: #e03f3f !important;
	border-color: #e03f3f
}
.p-hid-bol{
	bottom: 340px;
	right: 90px;
	width: 405px;
	z-index: 9;
	position: fixed;
}
.p-hid-waybill{
	bottom: 270px;
	right: 90px;
	width: 405px;
	z-index: 9;
	position: fixed;
}
.p-hid-invoice{
	bottom: 198px;
	right: 90px;
	width: 405px;
	z-index: 9;
	position:fixed;
}
.p-hid-docs{
	bottom: 125px;
	right: 90px;
	width: 405px;
	z-index: 9;
	position:fixed;
}
.p-hid-invoice2{
	bottom: 165px;
	right: 250px;
	//z-index: 9;
	position:fixed;
}
.p-up{
	position: relative;
	bottom: 70px;
}
.p-btn-left{
	position: relative;
	left: -120px;
}
.cp-btn-shift{
left: 1px !important;
width: 250px !important;

}

// .p-button.p-fileupload-choose {
//     position: relative;
//     overflow: hidden;
//     width: 132px;
// }

.p-fileupload .p-fileupload-buttonbar .p-button {
	width: 140px;
}

.p-fileupload .p-fileupload-buttonbar {
	display: flex;
  	justify-content: center;
}

.p-fileupload .p-fileupload-content {
	padding: 1em !important;
}

.p-fileupload .p-fileupload-row > div {
    padding: 1px !important;
	width: auto;
}

.p-fileupload .p-fileupload-row .p-fileupload-filename {
	overflow:hidden; 
	white-space:nowrap; 
	text-overflow: ellipsis;
	inline-size: 100px;
}

.p-fileupload .p-fileupload-row > div > img {
	display: none;
}

.p-datatable-tbody> tr> td {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100px;
	min-width: 100px;
}

.p-datatable-thead> tr> th {
	white-space: wrap;
	max-width: 100px;
	min-width: 100px;
}

// .App{
// 	overflow-y: hidden;
// 	overflow-x: scroll;
// 	// zoom:90%;
// }


.p-csz-right {
    right:180px !important;
		position: relative;
}

.p-button.p-button-icon-only {
    width: 3.5rem;
    padding: 0.5rem 0;
		position: relative;
}

.p-button.button-nowrap{
	white-space: nowrap;
}

.required {
  color: red;
}

div.p-datepicker.p-component.p-connected-overlay-enter-done {
	min-width: 418px !important;
	display:inline-grid;
}
.p-calendar .p-component .p-inputwrapper{
	width: 172px;
}

/*.p-loadlocation-pos{
	right: -200px !important;
	top:-255px !important;
	position: relative;

}*/

.p-divider-content{
	font-size: 0.8rem;
	font-weight: 700;
	background-color: #e1e2e3 !important;
	border-radius: 3px;
}

body{
	// overflow-y:hidden;
	// overflow-x: hidden;
	zoom: 80%;
}

body::-webkit-scrollbar {
    display: block;
    width: 5px;
}
body::-webkit-scrollbar-track {
    background: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: grey;
		position: sticky;
    border-right: none;
    border-left: none;
}

body::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
}

body::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
}

.p-field > label {
    display: inline-block;
    margin-bottom: 0.5rem;
    padding-left: 8px;
}

.p-datatable table {
	//really awesome css hack for prime react table layout!
	table-layout: auto;
}

.controlled-version {
	.version-container {
		position: relative;
		margin-bottom: 0px;
	}
}

.version-container {
	text-align: center;
	font-weight: 500;
	color: #8a97a7;
	font-size: 13px;
	width: 100%;
	margin-bottom: 0px;
	vertical-align: bottom;
}

.current-env-mode {
	text-align: center;
	font-size: 12px;
	background-color: #44af69;
	padding: 3px;
	vertical-align: middle;
	font-weight: 600;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 99;
	text-transform: uppercase;
	color: #fff;
	letter-spacing: 3px;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
	color: #6c757d;
	margin-left: 0.2rem;
	font-size: 13px;
}

.navbar.fixed-top {
	&.devmode {
		top: 24px;
	}
}

.center-title {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	h1 {
		font-weight: 800;

		.asw-title1 {
			color: #0054ab;
			font-size: 30px;
		}

		.asw-title2 {
			color: #EE283A;
		}
	}
}

nav {
	&.bg-light {
		background-color: #fff !important;
	}
}

h4 {
	&.main-title {
		font-weight: 800;
	}
}

.table-container {
    display: table;
    width: 100%;
	scrollbar-color: #0a4c95 #dcdcdc;

    &.custom-no-display-table {
        display: inherit;
    }
}

.card {
	padding: 1.25rem;
}

.nav-link {
	padding: 0;
}

.amerigas-logo {
	max-width: 150px;
}

.blue-amerigas-bg {
	background-color: #0054ab;
}

.blue-amerigas-txt {
	color: #0054ab;
}

.blue-100-amerigas-bg {
	background-color: #172fe6;
}

.padder-top {
	margin-top: 5rem !important;
}

.p-button.p-button-link {
	padding: 0;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
	background-color: transparent;
}

.form-warning {
	.p-inline-message-error {
		width: auto;
		margin-bottom: 10px;
		display: inline-block;
	}
}

.search-error {
	margin-top: -50px;
	padding: 20px 0;
	color: #ee283a;
}

.no-matching-filters {
	display: none;
	text-align: center;
	padding: 2rem;
	font-weight: 700;

	&.active {
		display: block;
	}
}

.spinner-dark-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	background: rgba(0, 0, 0, 0.2);

	&.historical-readings {
		background: transparent;
		position: relative;
		height: 50px;

		.p-progress-spinner {
			top: 70% !important;
			-ms-transform: translate(-50%, -70%) !important;
			transform: translate(-50%, -70%) !important;

		}
	}

	.inner-spinner-bg {
		position: relative;
		width: 100%;
		height: 100%;

		.p-progress-spinner {
			margin: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}
}

.no-access-container {
	.no-access-inner-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: left;
		max-width: 480px;
	}

	.no-access-icon {
		color: #35454c;
		float: left;
		margin-right: 15px;
		font-size: 5em;
	}

	h3 {
		color: #0054ab;
	}
}

/* FAQ */

#faq {
	width: 70vw;
	margin: 0 auto;
	padding-top: 3rem;
}

#faq h2 {
	text-align: center;
	margin-bottom: 2rem;
}

#faq ol {
	list-style: none;
	padding: 0;
}

#faq ol>li {
	width: 100%;
	margin: 0 auto;
	position: relative;
	min-height: 30px;
	margin-bottom: 8px;
	padding: 20px;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid #d1d1d1;

	&:before {
		content: "";
		width: 8px;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: #2196f3;
	}
}

#faq h3.question {
	width: 100%;
	//position: absolute;
	//top: 0;
	//left: 0;
	//margin: 0;
	//height: 30px;
	z-index: 1;
	font-size: 1.4rem;
	transition: all 0.3s;
}

#faq .toggle:checked~h3.question,
#faq li:hover h3.question {
	color: #0054ab;
}

#faq .icon {
	height: 18px;
	position: absolute;
	width: 18px;
	right: 0;
	top: 11px;
	z-index: 2;
}

#faq .icon:before,
#faq .icon:after {
	background: #607d8b;
	content: '';
	height: 4px;
	right: 14px;
	position: absolute;
	top: 10px;
	width: 18px;
	transition: transform 200ms ease;
	border-radius: 2px;

}

#faq .toggle:checked~.icon:before,
#faq .toggle:checked~.icon:after,
#faq li:hover .icon:before,
#faq li:hover .icon:after {
	background: #0054ab;
}

#faq .toggle:not(:checked)~.icon:before {
	transform: rotate(180deg);
}

#faq .toggle:not(:checked)~.icon:after {
	transform: rotate(90deg);
}

#faq .toggle {
	width: 100%;
	height: 40px;
	margin: 0;
	opacity: 0;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
}

#faq .border {
	height: 40px;
	border-bottom: 1px solid #fdfdfd;
}

#faq li:hover .border,
#faq .toggle:checked~.border {
	border-bottom: 1px solid #0054ab;
}

#faq .answer {
	height: 0;
	font-size: 16px;
	overflow: hidden;
	z-index: -1;
	position: relative;
	opacity: 0;
	transition: .4s ease;
}

#faq .toggle:checked~.answer {
	height: auto;
	opacity: 1;
	z-index: 2;
}

#faq .answer>p {
	margin: 0;
	text-align: justify;
}

@media only screen and (max-width: 600px) {
	.no-access-container {
		.no-access-inner-container {
			text-align: center;
		}

		.no-access-icon {
			color: #35454c;
			float: none;
			margin-right: 0;
			margin-top: 15px;
			font-size: 4em;
		}

		h3 {
			color: #0054ab;
			font-size: 18px;
		}
	}

	#faq {
		width: 100%;
	}
}

.p-dropdown {
	min-height: 40px;
}

.fade-in-bottom {
	-webkit-animation: fade-in-bottom 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-bottom 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
	0% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fade-in-bottom {
	0% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.help-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 18px;
	text-align: center;
	max-width: 654px;
	background-color: #fff;
	padding: 25px;
	border-radius: 4px;
	-webkit-box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.06);
	box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.06);
	border-bottom: 3px solid #d25a65;

	span {
		font-weight: 600;
	}

	h2 {
		color: #1E4CA1;
		margin-bottom: 20px;
		font-size: 2.4rem;
	}
}

.invoice-cards {
	border-radius: 4px;
	box-shadow: none;
	border: 2px solid #d9e9f9;
	color: #424649;
	transition: all 0.5s;

	&.active {
		border: 3px solid #fff;
		background-color: #ecf8ff;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

		.p-card-title {
			color: #2196f3;
		}

		.p-card-subtitle,
		.invoice-amount-text {
			color: #72899e;
		}

	}

	.invoice-amount {
		font-size: 24px;
		font-weight: 700;
		color: #36ab6f;
		background-color: #ecf7f1;
		border-radius: 50%;
		text-align: center;
		height: 70px;
		width: 70px;
		line-height: 67px;
		border: 1px solid #afe6db;

		&.zero {
			color: #adb5b9;
			background-color: #e8f0f5;
			border: 1px solid #e8ecef;
		}
	}

	.invoice-amount-text {
		font-size: 13px;
		font-weight: 700;
		color: #a3afba;
		margin-top: -4px;
	}

	.p-card-content {
		padding: 0;
	}

	.p-card-body {
		font-size: 14px;
	}

	.invoice-date {
		color: #587cbe;
	}

	.invoice-due {
		color: #ec818a;
	}

	.p-card-title {
		margin-bottom: -2px;
		font-size: 20px;
	}

	.invoice-amount-container {
		margin-bottom: 15px;
	}

	.p-card-subtitle {
		font-weight: 700;
		margin-bottom: 0.5rem;
		color: #a3afba;
	}

	.invoice-total {
		font-size: 1.5rem;
		font-weight: 700;
	}

	.button-container {
		.p-button {
			width: 100%
		}
	}
}

.coming-soon {
	text-align: center;

	.coming-soon-text {
		letter-spacing: 3px;
		color: #1e4ca1;
		font-size: 26px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: -4px;
	}

	.under {
		font-size: 18px;
		font-weight: 500;
		letter-spacing: 2px;
		color: #ee283a;
	}
}

.web-app {
	font-size: 14px;
	font-weight: 600;

	transition: all 0.5s;
	color: #7d97b5;

	&.active {
		color: #0054ab;
	}
}

.editor-cell {
	background-color: #fff;
	border: 1px solid #dbdbdb;
	padding: 6px;
	border-radius: 2px;
	-webkit-box-shadow: inset 0px 0px 1px 0px rgba(0, 0, 0, 0.42);
	box-shadow: inset 0px 0px 1px 0px rgba(0, 0, 0, 0.42);
	width: 100px;
	display: inline-block;
	min-height: 35px;

	&.date-custom-width {
		width: 120px;
	}

	&.p-invalid {
		border-color: #e96262;
		border-width: 3px;
	}
}

.p-cell-editing {
	.p-inputtext {
		width: 100%;
	}
}

.save-container {
	position: fixed;
	top: 0;
	right: 128px;
	z-index: 999;
	background-color: #fff;
	padding: 10px;
	text-align: center;
	-webkit-box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.1);
	border: 1px solid #d7e1e7;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;

	.p-button.p-button-sm {
		font-size: 0.775rem;
		padding: 0.3375rem 0.675rem;

	}
}

.sched-ship-date-icon {
	.p-column-title:after {
		content: 'i';
		margin: 4px;
		background-color: #dee7e9;
		padding: 0px 7px !important;
		border-radius: 50%;
		text-align: center;
		border: 1px solid #b1b2b3;
	}
}

.more-info-icon {
	background-color: #dee7e9;
	padding: 0px 4px;
	font-weight: 600;
	border-radius: 50%;
	text-align: center;
	border: 1px solid #b1b2b3;
	cursor: pointer;

	.pi {
		font-size: 14px;
		color: #0a4c95;
	}
}

.tips-text {
	font-size: 16px;
	font-weight: 500;
	color: #7f7f7f;
}

.p-sidebar-bottom {
	bottom: 0;
	right: 40px;
	width: 620px;
	height: 36.3rem;
	left: auto;
	border-top: 5px solid #0054ab;

	p {
		font-size: 13px;
	}

	&.p-sidebar .p-sidebar-header {
		padding: 0 !important;
	}

	.highlight-bold-text {
		color: #0054ab;
	}
}

.highlight-header {
	background: #ebed6d !important;
}

.simple-highlight{
	background-color:lightgoldenrodyellow !important;
	padding:0.1em 0.2em;
}